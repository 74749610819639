import React from 'react'
import SiteLogo from '../../images/logo-default.svg'
import RecycledImage from '../../images/recycledimage.jpg'

function Recycle() {
    return (
        <div>
            <div className="recycle-section section">
                <div className="container">
                    <div className="row align-items">
                        <div className="col-md-6">
                            <div className="column-left text-center">
                                <h2 className="row-title mb-4">Recycle, Make Money Go Environmentally Friendly</h2>
                                <img src={SiteLogo} width="200" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="column-right">
                                <img src={RecycledImage} alt=""/>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recycle
