import React from 'react'
import {NavLink} from 'react-router-dom'

// Layouts
import Recycle from '../layouts/Recycle'
import Intouch from '../layouts/Intouch'

function Error() {
    return (
        <div>

            <div className="section bg-grey error-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h1>Oh,</h1>
                                <h2 className="pb-4">Something went wrong. Please try again</h2>
                                <NavLink exact to="/" className="btn btn-primary customTertiaryBtn">Home Page</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <Recycle />
            <Intouch />
        </div>
    )
}

export default Error
