import React, {useEffect, useState} from 'react'
import Header from '../layouts/Header'

// Images 
import HeaderImage from '../../images/header.jpg' 
import CopperImage from '../../images/scrapcopper.jpg'
import AliminiumImage from '../../images/scrapaluminium.jpg'
import BrassImage from '../../images/scrapbrass.jpg'
import BatteryImage from '../../images/scrapbattery.jpg'
import MetalImage from '../../images/scrapmetal.jpg'
import SteelImage from '../../images/scrapsteel.jpg'


// Layouts 
import Recycle from '../layouts/Recycle'
import Intouch from '../layouts/Intouch'


function Services() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
        width < 750 && handleSideNavToggle();
    },[width]);

    function handleSideNavToggle() {
        console.log("toggle it");
    }
  return (
    <div>
        <Header pageTitle={`Service`}  headerBackground={HeaderImage} />
        
        <div className="section service-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media-item">
                            <div className={`media ${width < 750 && ('isMobile')}`}>
                                <div className="media-image mr-5 ">
                                    <img src={CopperImage}  alt="..." />
                                </div>
                                <div className="media-body">
                                    <h2 className="row-title mt-0">Copper Recycling</h2>
                                    <p>If you have any products that contain copper in your home or office we will pay you.</p> <p><strong>Call us NOW on 0469896003</strong></p>

                                    <p>Copper is a very desirable metal in the scrap industry. We offer very competitive prices for your copper. It is a staple in the scrap industry due to it being hard wearing and long lasting. Use this to your advantage and get the most out of your scrap copper! </p>

                                    <p>Ensuring you get the best price by keeping current with market values. Copper can be found in a number of products like plumbing tubes, heat exchangers, roofing sheets, PVC wires, Air Conditioners, HVAC tubing and conduits.</p>

                                    <p>Don't throw away your copper - chose to get some cash and in doing so you will help keep our environment a happy place by reducing waste! </p>
                                    <p>Copper is not all the same, 
                                        <ul>
                                            <li>die cast parts have a higher price per kilo.</li>
                                            <li>Copper scraps that are used in generators and electrical motors and copper metal wire also producing a high price per kilo.</li>
                                        </ul>
                                        
                                    </p> 
                                    <p>With the market value constantly changing contact us for the best available price.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section bg-grey service-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media-item">
                            <div className={`media ${width < 750 && ('isMobile')}`}>
                                <div className="media-image mr-5">
                                    <img src={AliminiumImage}  alt="..." />
                                </div>
                                <div className="media-body">
                                    <h2 className="row-title mt-0">Aluminium Recycling</h2>
                                    <p>Sher Scrap Metal Pty Ltd will buy your aluminium scrap. We ensure you receive the best rates by basing our prices on current market value. We will come and collect your aluminium so give us a call today on <strong>0469896003</strong></p>
                                    <p>There are several different types of aluminium in which we pay and recycle for all such as aluminium cans, cast aluminium, extruded aluminium, sheet aluminium, aluminium wheels, general aluminium. </p> <p><strong>We offer a quick and easy service and pay by the weight.</strong></p>
                                    <p>At Sher Scrap Metal Pty Ltd our first priority is to provide the highest level of aluminium recycling services to all our customers. We are highly experienced in current aluminium recycling prices and pay per kilo, truck load and tonne.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section service-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media-item">
                            <div className={`media ${width < 750 && ('isMobile')}`}>
                                <div className="media-image mr-5">
                                    <img src={BrassImage}  alt="..." />
                                </div>
                                <div className="media-body">
                                    <h2 className="row-title mt-0">Brass Recycling</h2>
                                    <p>Brass is more malleable than steel or iron as it’s easier to bend, shape or mold than most metals. This makes is ideal for fields such as plumbing. Brass is a high grade scrap metal and prices are usually higher than other metals. </p>
                                    <p> Our major goal here at Sher Scrap Metal Pty Ltd is to reduce the amount of scrap metal filling our landfills. Most scrap metal can be repurposed and used to make new products. </p>
                                    <p> Our commitment to this mission allows us to offer clients tailor-made solutions. So if you have scrap you’re about to dispose of, don’t bin it, recycle it. Contact us for a free assessment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section bg-grey service-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media-item">
                            <div className={`media ${width < 750 && ('isMobile')}`}>
                                <div className="media-image mr-5">
                                    <img src={BatteryImage}  alt="..." />
                                </div>
                                <div className="media-body">
                                    <h2 className="row-title mt-0">Battery Recycling</h2>
                                    <p>We recycle all lead acid batteries, sealed lead batteries, industrial batteries, steel case and solar batteries which are all paid by the kilogram.</p>
                                    <p>We buy scrap batteries from individuals, companies, and businesses alike and pay good money for them. We collect all types of scrap batteries. Contact us to find out how much your scrap batteries are potentially worth.</p>
                                    <p>If you’re not sure what to do with the scrap batteries in your possession, fret no more. Sher Battery Recycling is here to help. We’ll not only pick up the scrap battery and get it off your hands but pay you a fair price for it. Our ethos is don’t bin it, recycle it and get scrap for cash. Our job is to keep scrap batteries from landfill sites and instead sort through them and recycle them.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section service-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media-item">
                            <div className={`media ${width < 750 && ('isMobile')}`}>
                                <div className="media-image mr-5">
                                    <img src={MetalImage}  alt="..." />
                                </div>
                                <div className="media-body">
                                    <h2 className="row-title mt-0">Metal Recycling</h2>
                                    <p>We collect anything that’s metal both ferrous and non-ferrous. From old batteries to vehicles.Our major goal here at Sher Scrap Metal Pty Ltd is to reduce the amount of scrap metal filling our landfills. </p>
                                    <p> Our major goal here at Sher Scrap Metal Pty Ltd is to reduce the amount of scrap metal filling our landfills. Most scrap metal can be repurposed and used to make new products. </p>
                                    <p> Our commitment to this mission allows us to offer clients tailor-made solutions. So if you have scrap you’re about to dispose of, don’t bin it, recycle it. Contact us for a free assessment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section bg-grey service-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media-item">
                            <div className={`media ${width < 750 && ('isMobile')}`}>
                                <div className="media-image mr-5">
                                    <img src={SteelImage}  alt="..." />
                                </div>
                                <div className="media-body">
                                    <h2 className="row-title mt-0">Steel Recycling</h2>
                                    <p>We collect anything that’s metal both ferrous and non-ferrous. From old batteries to vehicles. Our major goal here at Sher Scrap Metal Pty Ltd is to reduce the amount of scrap metal filling our landfills. </p>
                                    <p>Our major goal here at Sher Scrap Metal Pty Ltd is to reduce the amount of scrap metal filling our landfills. Most scrap metal can be repurposed and used to make new products. </p>
                                    <p>Our commitment to this mission allows us to offer clients tailor-made solutions. So if you have scrap you’re about to dispose of, don’t bin it, recycle it. Contact us for a free assessment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Recycle />
        <Intouch />
    </div>
)
}

export default Services
