import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'

// Image 
import SiteLogo from '../../images/logo-default-light.svg'
import scrollTopImg  from '../../images/up-arrow.svg'
import byStrivell  from '../../images/bystrivell.svg'

// Fontawesome 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {

    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 200){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 200){
            setShowScroll(false)
        }
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        <div className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer-logo pb-4">
                            <img src={SiteLogo} width="250" alt="Sher Scrap Metal Pty Ltd"/>
                        </div>
                        <div className="footer-social">
                            <h3>@sherscrapmetal</h3>

                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/sherscrapmetal"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" color="#fff" /></a>
                                </li>
                                
                                <li>
                                    <a href="https://www.instagram.com/sherscrapmetal/"><FontAwesomeIcon icon={['fab', 'instagram']} size="lg" color="#fff" /></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                        <div className="footer-quick-links">
                            <h3>Quick Links</h3>
                            <ul>
                                <li><NavLink exact to="/">Home</NavLink></li>
                                <li><NavLink to="/about">About Us</NavLink></li>
                                <li><NavLink to="/services">Services</NavLink></li>
                                <li><NavLink to="/contact">Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-contact">
                            <h3>Contact us</h3>
                            <ul>
                                <li>
                                    <div>
                                        <a href="tel:0469896003">0469896003</a>
                                        <p>ARIF</p>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <a href="mailto:info@sherscrapmetal.com.au">info@sherscrapmetal.com.au</a>
                                        <p>We reply within 24 hours</p>
                                    </div>
                                </li>
                                <li>
                                    <a href="https://goo.gl/maps/d2iewW1MACmikewi9">14-16 Marjorie St<br/>Sefton NSW 2162</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <p>© 2020-2022 Sher Scrap Metal Pty Ltd. All rights reserved.</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <a href="https://www.strivell.com">
                            <img src={byStrivell} width="120" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="scroll-top">
                <button className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'block' : 'none'}}> <img src={scrollTopImg} alt=""/> </button>
            </div>
        </div>
    )
}

export default Footer

