import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min'
import 'bootstrap/dist/js/bootstrap.min'
import './App.css'


import Navbar from './component/layouts/Navbar'
import Footer from './component/layouts/Footer'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import Home from './component/pages/Home'
import About from './component/pages/About'
import Services from './component/pages/Services'
import Gallery from './component/pages/Gallery'
import Contact from './component/pages/Contact'
import Error from './component/pages/Error'



// Fontawesome 
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)


class App extends Component {
    render() {
        return (
            <div className="App">
                
                <Router>
                    <div>
                        <Navbar />
                        
                        <Route exact path="/" component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/services" component={Services} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/error" component={Error} />



                        <Footer />
                    </div>
                </Router>
            </div>
        )
    }
}

export default App

