import React, { useState, useCallback } from "react";
import Header from '../layouts/Header'

// Images 
import HeaderImage from '../../images/header.jpg' 
import { photos } from "./Photos";

// Gallery 
import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

// Layouts 
import Recycle from '../layouts/Recycle'
import Intouch from '../layouts/Intouch'




function Gallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <div>
            <Header pageTitle={`Gallery`}  headerBackground={HeaderImage} />
            <div className="section bg-grey gallery-section">
                <div className="container">
                    {/* <h2 className="row-title text-center">Gallery</h2> */}
                    <div className="gallery-row pt-5">
                        <div className="row">
                            <div className="col-md-12">

                                <PhotoGallery photos={photos} onClick={openLightbox} />
                                <ModalGateway>
                                    {viewerIsOpen ? (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={photos.map(x => ({
                                                    ...x,
                                                    srcset: x.srcSet,
                                                    caption: x.title
                                                }))}
                                            />
                                        </Modal>
                                    ) : null}
                                </ModalGateway>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Recycle />
            <Intouch />
        </div>
    )
}

export default Gallery
