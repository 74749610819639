import React from 'react'

function Header(props) {
    var headerStyle = {
        backgroundImage: `url(${ props.headerBackground })`
    };
    
    return (
        <div className="header-section" style={headerStyle} >
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <h1>{props.pageTitle}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}


export default Header
