import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

// Navbar images 
import CallIcon  from '../../images/phone.svg'
import MapIcon  from '../../images/location.svg'
import SiteLogo from '../../images/logo-default.svg'
import Facebook from '../../images/facebook.png'
import Instagram from '../../images/instagram.png'


// FontAwesome
import MenuBarsImage from '../../images/menubars.svg'



class Navbar extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             animate: false
        }

        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        this.setState((prevState => {
            return { animate : !prevState.animate}
        }))
    }


    render() {

        let animateClass = (this.state.animate ? 'active' : '');

        return (
            <div>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p0">
                                <p className="text-right collapse navbar-collapse ">Mon- Sat 7:00 AM to 5:00 PM | info@sherscrapmetal.com.au</p>
                                <div className="logo-contact">
                                    <NavLink exact={true} to="/" className="nav-brand">
                                        <img src={SiteLogo} width="250" alt="Sher Scrap Metal Pty Ltd"/>
                                    </NavLink>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"><img src={MenuBarsImage} alt=""/></span>
                                    </button>
                                    <div className="navbar-contact collapse navbar-collapse ">
                                        <ul>
                                            <li>
                                                <img src={CallIcon} width="70" alt=""/>
                                                <div>
                                                    <a href="tel:0469896003">0469896003</a>
                                                    <p><span>Arif</span></p>
                                                </div>
                                            </li>
                                            
                                            <li>
                                                <img src={MapIcon} width="70" alt=""/>
                                                <p>14-16 Marjorie St<br/>Sefton NSW 2162</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 p0">
                                <div className={`collapse navbar-collapse ${animateClass}`} id="navbarSupportedContent" >
                                    {/* <ul className=""></ul> */}
                                    <div className="navbar-nav">  
                                        <NavLink exact to="/" className="nav-item nav-link" onClick={this.handleClick}>Home</NavLink>        
                                        <NavLink to="/about" className="nav-item nav-link" onClick={this.handleClick}>About Us</NavLink>
                                        <NavLink to="/services" className="nav-item nav-link" onClick={this.handleClick}>Services</NavLink>
                                        <NavLink to="/gallery" className="nav-item nav-link" onClick={this.handleClick}>Gallery</NavLink>
                                        <NavLink to="/contact" className="nav-item nav-link" onClick={this.handleClick}>Contact Us</NavLink>
                                        <ul className="mr-auto"></ul>
                                        <a href="https://www.facebook.com/sherscrapmetal" className="nav-item nav-link" target="_blank" onClick={this.handleClick}><img src={Facebook} alt=""/></a>
                                        <a href="https://www.instagram.com/sherscrapmetal" className="nav-item nav-link" target="_blank" onClick={this.handleClick}><img src={Instagram} alt=""/></a>
                                        <NavLink to="/contact" className="nav-item nav-link btn btn-primary customSecondaryBtn" onClick={this.handleClick}>Get a Quote</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navbar
