import React from 'react'
import Header from '../layouts/Header'
import HeaderImage from '../../images/header.jpg' 


// Layouts
import Recycle from '../layouts/Recycle'
import Intouch from '../layouts/Intouch'

function About() {
    return (
        <div>
            <Header pageTitle={`About`}  headerBackground={HeaderImage} />

            <div className="section bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 pb-3">
                            <h2 className="row-title"><span>We Are A Team </span><span>of Skilled Experts</span></h2>
                        </div>
                        <div className="col-md-12">
                            <div className="full-width">
                                
                                <p>Sher Scrap Metal Pty Ltd is based in Sydney servicing for all types of scrap metals throughout NSW areas we are leading scrap metal, battery recyclers. </p>
                                <p>We strive to be the most competitive recycling company in Sydney and pay the best prices for all scrap metal and batteries. Sher Scarp Metal Pty Ltd has developed an extremely good relationship with some of the leading scrap metal yards in Australia which enable us to offer all of our customers the best possible prices.</p> 
                                <p> We will try and beat any price any other scrap yard has given you for all types of scrap metal, copper and batteries.</p>

                                <p>A lot of people dump rubbish to avoid waste disposal costs. <br/> <strong>Next time call us to collect or come into Sher Scrap Metal Pty Ltd to get PAID FOR YOUR SCRAP INSTEAD OF INCURRING AN EXPENSE.</strong> </p> 
                                <p>Our yard which is conveniently located at 14-16 Majorie St Sefton 2162 is equipped with machinery to handle nearly everything. Our friendly and helpful staff will unload all of your scrap metal, weigh on site and pay you onsite; that is how easy it is. </p>
                                <p>For your convenience Sher Scrap Metal Pty Ltd also supplies a reliable pick up service within the Sydney and surrounding areas. Our trucks are fitted with the right equipment to handle the collection of small or large loads.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <Recycle />
            <Intouch />
        </div>
    )
}

export default About
