import React, {useEffect, useState} from 'react'
import Typed from 'react-typed'

function Hero() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
        width < 750 && handleSideNavToggle();
    },[width]);

    function handleSideNavToggle() {
        console.log("toggle it");
    }

    
    return (
        <div className="hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="hero-content ">
                            <div>
                                <span className={`hero-title ${width < 750 && ('isMobile')}`}>Scrap Metal Recycling Service in Sydney</span>
                                <br/>
                                <Typed
                                    strings={[
                                        'We buy Copper',
                                        'We buy Aluminium',
                                        'We buy Radiator',
                                        'We buy Brass',
                                        'We buy Battery',
                                        'We buy Steel',
                                        'We buy Wire'
                                    ]}
                                        typeSpeed={40}
                                        backSpeed={50}
                                        loop >
                                </Typed>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
