import React, {useEffect, useState} from 'react'

import Hero from '../layouts/Hero'

// Images 
import RecycleImage from '../../images/recycled.jpg'
import FeatureImage from '../../images/feature.jpg'



// Scrap images 
import ScrapCopper from '../../images/scrapcopper.jpg'
import ScrapBrass from '../../images/scrapbrass.jpg'
import ScrapAluminium from '../../images/scrapaluminium.jpg'
import ScrapCables from '../../images/scrapcables.jpg'
import ScrapMetal from '../../images/scrapmetal.jpg'
import SteelIron from '../../images/steelandiron.jpg'
import ScrapRadiator from '../../images/scrapradiator.jpg'
import ScrapBatteries from '../../images/scrapbatteries.jpg'


import Reviews from "../../images/reviews.jpg"
import RecyclingProfile from '../../images/recyclingprofile.png'




// Layouts 
import Recycle from '../layouts/Recycle'
import Intouch from '../layouts/Intouch'

function Home() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    useEffect(() => {
        width < 750 && handleSideNavToggle();
    },[width]);

    function handleSideNavToggle() {
        console.log("toggle it");
    }
    
    return (
        <div>
            
            <Hero />
            <div className="section">
                <div className="container">
                    <div className="row align-items">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <h2 className="row-title text-center">Your satisfaction is our top priority</h2>
                            <p className="text-center">We buy all kinds of scrap metal, save you time, pay you top price on the spot, provide the best possible customer service.</p>
                            
                        </div>
                        <div className="col-md-6">
                            <div className="column-left p-2">
                                <ul className="tags">
                                    <li>
                                        <p>⏰ 24 Hours Availability</p>
                                    </li>
                                    <li>
                                        <p>🤙 Free Quotation</p>
                                    </li>
                                    <li>
                                        <p>🔥 Same Day Service!</p>
                                    </li>
                                    <li>
                                        <p>🚚 Free Pick Up</p>
                                    </li>
                                    <li>
                                        <p>👥 Dedicated Team</p>
                                    </li>
                                    
                                    <li>
                                        <p>💵 Top Price</p>
                                    </li>
                                    
                                    <li>
                                        <p>🛠️ Metal Recycling</p>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="column-right text-center">
                                <img src={RecycleImage}  alt=""/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="section bg-grey">
                <div className="container">
                    <div className="row align-items">
                        <div className="col-md-6">
                            <div className="column-left pr-2">
                                <img src={FeatureImage} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="column-right">
                                <h2 className="row-title"><span>Scrap metal recycling</span> <br/><span>service in Sydney</span></h2>
                                <p>We deal with anyone and everyone. It doesn’t matter whether you have a few kg of metal scraps or you would be willing to sell hundreds of tons to us, we would be happy to buy from you.</p>
                                <ul>
                                    <li><p>Licensed & accredited scrap metal recycler</p></li>
                                    <li><p>Australian owned & operated private company</p></li>
                                    <li><p>Free scrap metal pick ups around Sydney</p></li>
                                    <li><p>Ferrous & non-Ferrous scrap metal recyclers</p></li>
                                    <li><p>Aluminium, Steel, Copper, Brass metal recyclers</p></li>
                                    <li><p>Most competitive prices for scrap metal in town</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <h2 className="row-title text-center">What we buy</h2>
                
                    <div className="row align-items pt-5">
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapCopper} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Copper Scrap</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapBrass} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Brass Scrap</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapAluminium} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Aluminium Scrap</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapCables} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Insulated Cables</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapMetal} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Ferrous Metal</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={SteelIron} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Steel & Iron Scrap</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapRadiator} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Radiators</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="product-item text-center">
                                <div className={`product-image ${width < 750 && ('isMobile')}`}>
                                    <img src={ScrapBatteries} alt=""/>
                                </div>
                                <div className="product-info">
                                    <h4>Battery Scrap</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section bg-grey">
                <div className="container">
                    <h2 className="row-title text-center">What they say about us</h2>
                    <div className="row align-items pt-5">
                        <div className="col-md-6">
                            <div className="column-left p-5">
                                <img src={Reviews}  alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="media-item">
                                <div className="media">
                                    <div className="media-image mr-3">
                                        <img src={RecyclingProfile}  alt="..." />
                                    </div>
                                    <div className="media-body">
                                        <h5 className="mt-0 weight-700">Ringdal</h5>
                                        <p>Gorgeous, just gorgeous I love this theme. The nicest theme I ever worked with and I have worked with hundreds of them. Thanks for beautiful work, keep it up!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="media-item">
                                <div className="media">
                                    <div className="media-image mr-3">
                                        <img src={RecyclingProfile}  alt="..." />
                                    </div>
                                    <div className="media-body">
                                        <h5 className="mt-0 weight-700">Tomdekok</h5>
                                        <p>Excellent service and favorable payment for the scrap they collected from my house. Happy with their service.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="media-item">
                                <div className="media">
                                    <div className="media-image mr-3">
                                        <img src={RecyclingProfile}  alt="..." />
                                    </div>
                                    <div className="media-body">
                                        <h5 className="mt-0 weight-700">Marcus Finn</h5>
                                        <p>These guys have made it pretty easy and simple at Sher Scrap Metal Pty Ltd. The service is very good and the best thing about them is that they actually gave me the best rates ...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Recycle />
            <Intouch />
        </div>
    )
}

export default Home
