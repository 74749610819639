import Gallery1 from '../../images/gallery1.jpg'
import Gallery2 from '../../images/gallery2.jpg'
import Gallery3 from '../../images/gallery3.jpg'
import Gallery4 from '../../images/gallery4.jpg'
import Gallery5 from '../../images/gallery5.jpg'
import Gallery6 from '../../images/gallery6.jpg'
import Gallery7 from '../../images/gallery7.jpg'
import Gallery8 from '../../images/gallery8.jpg'
import Gallery9 from '../../images/gallery9.jpg'
import Gallery10 from '../../images/gallery10.jpg'
import Gallery11 from '../../images/gallery11.jpg'
import Gallery12 from '../../images/gallery12.jpg'
import Gallery13 from '../../images/gallery13.jpg'
import Gallery14 from '../../images/gallery14.jpg'
import Gallery15 from '../../images/gallery15.jpg'
import Gallery16 from '../../images/gallery16.jpg'
import Gallery17 from '../../images/gallery17.jpg'
import Gallery18 from '../../images/gallery18.jpg'

export const photos = [
    {
        src: Gallery1,
        width: 4,
        height: 3
    },
    {
        src: Gallery2,
        width: 1,
        height: 1
    },
    {
        src: Gallery3,
        width: 3,
        height: 4
    },
    {
        src: Gallery4,
        width: 8,
        height: 6
    },
    {
        src: Gallery5,
        width: 3,
        height: 4
    },
    {
        src: Gallery6,
        width: 4,
        height: 3
    },
    {
        src: Gallery7,
        width: 3,
        height: 4
    },
    {
        src: Gallery8,
        width: 4,
        height: 3
    },
    {
        src: Gallery9,
        width: 4,
        height: 3
    },
    {
        src: Gallery10,
        width: 4,
        height: 3
    },
    {
        src: Gallery11,
        width: 1,
        height: 1
    },
    {
        src: Gallery12,
        width: 3,
        height: 4
    },
    {
        src: Gallery13,
        width: 3,
        height: 4
    },
    {
        src: Gallery14,
        width: 3,
        height: 4
    },
    {
        src: Gallery15,
        width: 4,
        height: 3
    },
    {
        src: Gallery16,
        width: 3,
        height: 4
    },
    {
        src: Gallery17,
        width: 4,
        height: 3
    },
    {
        src: Gallery18,
        width: 4,
        height: 3
    }
];
  