import React from 'react'

function Intouch() {
    return (
        <div>
            <div className="intouch-section">
                <div className="container">
                    <div className="intouch-row">
                        <div className="row">
                            <div className="col-md-9">
                                <h2>Call us today and get paid Today</h2>
                                <p>We will give you a price on <strong>WHATEVER YOU GOT!</strong></p>
                            </div>
                            <div className="col-md-3 text-right">
                                <a href="tel:0469896003" className="btn btn-primary customTertiaryBtn">Give us a call</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intouch
