import React, { Component } from 'react'
import Header from '../layouts/Header'

// images 
import HeaderImage from '../../images/header.jpg' 
import ContactImage from '../../images/catalytic.jpg'


// Fontawesome 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Layouts 
import Recycle from '../layouts/Recycle'
import Intouch from '../layouts/Intouch'

// Email 
import emailjs from 'emailjs-com'
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

class Contact extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            fname: '',
            lname: '',
            phone: '',
            email: '',
            message: '',
            errors: {
                fname: '',
                lname: '',
                phone: '',
                email: ''
              }
        }
    }

    

    handelInputChanges(event){
        event.preventDefault();
        const target = event.target;
        const name = target.name;
        const value = target.value;

        let errors = this.state.errors;

        switch (name) {
            case 'fname': 
                errors.fname = 
                value.length < 3 ? 'First name must be 3 characters at least!' : '';
                break;
            case 'lname': 
                errors.lname = 
                value.length < 3 ? 'First name must be 3 characters at least!' : '';
                break;
            case 'phone': 
                errors.phone = 
                value.length < 3 ? 'Invalid phone number!' : '';
                break;
            case 'email': 
                errors.email = 
                validEmailRegex.test(value) ? '' : 'Email is not valid!';
                break;
            default:
              break;
        }

        this.setState({ [name] : value});
    }

    sendMessage(event) {
        event.preventDefault();
        if(validateForm(this.state.errors)) {
            const templateParams = {
                from_name: this.state.fname + " "+ this.state.lname + " (" + this.state.email + ")",
                to_name: "Sher Scrap Metal Pty Ltd",
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            };
        

            emailjs.send("gmail", "contact", templateParams, 'user_XI7gEhC5OZQ6FawfIjQ5g')
                .then(
                    function(response) {
                        
                        toast.success("Your message has successfully sent!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        console.log("SUCCESS!", response.status, response.text);
                    },
                    function(err) {
                        toast.error("Your message was not able to be sent");
                        console.log("Your message was not able to be sent");
                    }
                );
                this.setState({
                    fname: '',
                    lname: '',
                    phone: '',
                    email: '',
                    message: ''
                });
        }else{
            console.error('Invalid Form')
        }
        
    }
    
    render() {
        return (
            <div>
                <Header pageTitle={`Contact`}  headerBackground={HeaderImage} />

                <div className="section bg-grey">
                    <div className="container">
                        <div className="row align-items">
                            <div className="col-md-6">
                                <div className="column-left pr-3 text-center">
                                <img src={ContactImage} alt=""/>

                                <div className="social-follow text-center pt-3">
                                    
                                        <h4>Follow us on</h4>
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/sherscrapmetal"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" color="#000" /></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/sherscrapmetal/"><FontAwesomeIcon icon={['fab', 'instagram']} size="lg" color="#000" /></a>
                                            </li>

                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="column-right">
                                    <h2 className="row-title"><span>Get in touch</span> <span></span></h2>
                                    <p>Have an inquiry or some feedback for us? <br/>Fill out the form below to contact our team.</p>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        />
                                    <form 
                                        className="pt-4" 
                                        id={this.props.id}
                                        name={this.props.name}
                                        method={this.props.method}
                                        action={this.props.action}>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="fname">First name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="fname" 
                                                    id="fname" 
                                                    placeholder="Jane"
                                                    onChange={this.handelInputChanges.bind(this)}
                                                    value={this.state.fname}
                                                    noValidate
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="lname">Last name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="lname" 
                                                    id="lname" 
                                                    placeholder="Smith"
                                                    onChange={this.handelInputChanges.bind(this)}
                                                    value={this.state.lname}
                                                    noValidate
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="phone">Phone</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="phone" 
                                                    id="phone" 
                                                    placeholder="04xxxxxxx"
                                                    onChange={this.handelInputChanges.bind(this)}
                                                    value={this.state.phone}
                                                    noValidate
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="email">Email</label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    placeholder="janesmith@example.com"
                                                    onChange={this.handelInputChanges.bind(this)}
                                                    value={this.state.email}
                                                    noValidate
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="message">Message</label>
                                                <textarea
                                                    type="textarea"
                                                    className="form-control" 
                                                    name="message"
                                                    id="message" 
                                                    rows="3" 
                                                    placeholder="Hey friends, I'd love to talk to you about..."
                                                    value={this.state.message}
                                                    onChange={this.handelInputChanges.bind(this)}
                                                     
                                                />
                                            </div>
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary customPrimaryBtn"
                                                onClick={this.sendMessage.bind(this)}
                                            >Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Recycle />
                <Intouch />
            </div>
        )
    }
}

export default Contact

